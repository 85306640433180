export default function Custom404() {
  return (
    <>
      <style jsx>
        {`
          h1 {
            color: var(--color-font-black);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        `}
      </style>
      <h1>404 - Page Not Found</h1>
    </>
  );
}
